const state = {
  cartArr: [],
};

const getters = {
  getCartArr: state => state.cartArr,
};

const mutations = {
  addToCart(state, { product, quantity, size }) {
    let foundProductIndex = state.cartArr.findIndex(prod => prod.id === product.id && prod.size === size);
    if (foundProductIndex !== -1) {
      let newQuantity = state.cartArr[foundProductIndex].quantity + quantity;
      state.cartArr[foundProductIndex].quantity = newQuantity;
    } else {
      state.cartArr.push({ ...product, quantity: quantity, size: size });
    }
  },
  removeFromCart(state, id) {
    state.cartArr = state.cartArr.filter(x => x.id !== id);
  },
  incrementQuantity(state, index) {
    state.cartArr[index].quantity += 1;
  },
  decrementQuantity(state, index) {
    state.cartArr[index].quantity -= 1;
  },
  emptyCart(state) {
    state.cartArr = [];
  },
};

const actions = {
  async addToCart({ commit }, { product, quantity, size }) {
    commit('addToCart', { product, quantity, size });
  },

  removeFromCart({ commit }, id) {
    commit('removeFromCart', id);
  },

  incrementQuantity({ commit }, index) {
    commit('incrementQuantity', index);
  },

  decrementQuantity({ commit }, index) {
    commit('decrementQuantity', index);
  },

  emptyCart({ commit }) {
    commit('emptyCart');
  },
};

export const productsStore = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
