import { get, last, map } from 'lodash';
import moment from 'moment';
import languageNameMap from 'language-name-map/map';

import { sanitizeUrl } from '@braintree/sanitize-url';

/**
 * Parse file name from the given url of an uploaded file.
 * @param {string} url File url.
 * @param {boolean} removeTimestamp Wheather or not to remove the timestamp from start of the file name.
 * @returns Parsed file name.
 */
export function parseFileNameFromUrl(url, removeTimestamp = false) {
  let fileName = decodeURI(last(url.split('/')));
  if (removeTimestamp) fileName = fileName.replace(/^[0-9.]+_/, '');
  return fileName;
}

export function formatFullDate(date) {
  return moment(date).format('MMMM DD, YYYY');
}

export function stdPlanToPayOptions(tuitionFee) {
  return [
    {
      value: 'self_pay',
      text: 'Self Pay',
      description: `I will be paying the ${(tuitionFee || '').toLowerCase() || 'tuition'} myself.`,
      icon: 'fa-money-check-alt',
    },
    {
      value: 'sponsored_employer_agency',
      text: 'Sponsored',
      description: `A sponsor will be paying the ${(tuitionFee || '').toLowerCase() ||
        'tuition'} (e.g. Employer, Agency).`,
      icon: 'fa-building',
    },
    {
      value: 'not_sure_yet',
      text: 'Loan',
      description: `A finance company will be paying the ${(tuitionFee || '').toLowerCase() || 'tuition'}.`,
      icon: 'fa-university',
    },
  ];
}

export function formatTime(time) {
  return moment(time, 'HH:mm').format('hh:mm A');
}
export function formatTimeZone(zone) {
  return moment.tz(zone).zoneAbbr();
}

export function formatDayDate(date) {
  return moment(date).format('dddd, MM/DD/YYYY');
}

export function formatDateSimple(date) {
  return moment(date).format('MM/DD/YY');
}

export function formatDateFullYear(date) {
  return moment(date).format('MM/DD/YYYY');
}

export function formatFullDateTime(dateTime) {
  return moment(dateTime).format('MMMM DD, YYYY, hh:mm A');
}
export function formatDateTime(dateTime) {
  return moment(dateTime).format('MMM DD, YYYY, hh:mm A');
}
export function formatTimeAgo(dateTime) {
  return moment(dateTime).fromNow();
}

export function formatToAPIDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

export function formatHrefLink(string) {
  const regex = /(https?:\/\/[\w.\d:?&=#]+)/gm;
  const subst = `<b><a style="color:inherit;" target="_blank" href="$1">$1</a></b>`;
  return string.replace(regex, subst);
}

export function getLangOptions(nameKey = 'name') {
  return Object.keys(languageNameMap).map(key => ({ value: key, [nameKey]: languageNameMap[key].name }));
}

export function getClickableLink(link) {
  return link.startsWith('http://') || link.startsWith('https://') ? sanitizeUrl(link) : sanitizeUrl(`https://${link}`);
}

export const NOTIFICATION_EXTRA_MAPPINGS = (action, notification) => {
  return {
    program_info_query: [notification.extra.name, notification.extra.program],
    student_registration: [notification.extra.student_name, notification.extra.program, notification.extra.class_id],
    pre_enrollment_created: [notification.extra.full_name, notification.extra.program],
    app_feature_update: [notification.extra.notification_title],
    public_instructor_creation: [notification.extra.full_name],
    refund_requested: [notification.extra.refund_school_name],
    export_analytics: [formatDateSimple(notification.extra.from_date), formatDateSimple(notification.extra.to_date)],
    partnership_requested: [get(notification, 'extra.school_name', 'A school')],
    partnership_accepted: [get(notification, 'extra.school_name', 'The training partner')],
  }[action];
};

export function titleize(value) {
  return value.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
}

export function getHumanReadableCommaSepStr(arr, lastSep = 'and') {
  return [arr.slice(0, -1).join(', '), arr.slice(-1)[0]].join(arr.length < 2 ? '' : ` ${lastSep} `);
}

export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function getWorkTravelOptions() {
  const options = map([...Array(9).keys()], num => ({
    value: `${(num + 1) * 10}`,
    text: `${(num + 1) * 10} miles`,
  }));
  options.push({ value: '100+', text: '100+ miles' });
  return options;
}

export function stripHtmlTags(value) {
  const noTags = (value || '').replace(/(<([^>]+)>)/gi, '');
  return (noTags + '').replace(/&#\d+;/gm, s => String.fromCharCode((s.match(/\d+/gm) || [])[0]));
}

export function computeAddress(data, keys, stateKey = 'state', zipcodeKey = 'zipcode') {
  let address = '';
  keys.forEach(item => {
    address = address
      .concat(
        item === zipcodeKey && data[stateKey] && data[zipcodeKey] ? ' ' : data[item] && address !== '' ? `, ` : ''
      )
      .concat(data[item] ? data[item] : '');
  });
  return address;
}
