import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllProducts(_obj, params) {
    const res = await axios.get(`api/products/`, { params });
    return res.data;
  },

  async getProduct(_obj, id) {
    const res = await axios.get(`api/products/${id}/`);
    return res.data;
  },

  async createProduct(_obj, data) {
    return axios.post(`api/products/`, data);
  },

  async updateProduct(_obj, { id, data }) {
    return axios.patch(`api/products/${id}/`, data);
  },
  async getProductImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/products/upload-url/`, data)).data.data;
  },

  async deleteProduct(_obj, id) {
    return axios.delete(`api/products/${id}/`);
  },
  async linkProdWithProgram(_obj, data) {
    return axios.post(`api/products/link-program/`, data);
  },

  async unlinkProdFromProgram(_obj, data) {
    return axios.post(`api/products/unlink-from-program/`, data);
  },

  async getProgramLinkedProducts(_obj, params) {
    const res = await axios.get(`api/products/prg-linked-prd/`, { params });
    return res.data;
  },
  async getPublicProducts(_obj, params) {
    const res = await axios.get(`api/products/public-product-list/`, { params });
    return res.data;
  },
  async updateProductPurchase(_obj, { id, data }) {
    return axios.patch(`api/products/product-purchase/${id}/`, data);
  },

  async getAllPurchasedProducts(_obj, params) {
    const res = await axios.get(`api/products/product-purchase/`, { params });
    return res.data;
  },

  async getExternalPurchasedProducts(_obj, params) {
    const res = await axios.get(`api/products/product-purchase/external/`, { params });
    return res.data;
  },
};

export const products = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
