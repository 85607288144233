import axios from 'axios';

const state = {
  payoutAccountStatus: null,
};

const getters = {
  getPayoutAccountStatus: state => state.payoutAccountStatus,
};

const mutations = {
  updatePayoutAccountStatus: (state, status) => {
    state.payoutAccountStatus = status;
  },
};

const actions = {
  async verifyAccount({ commit }, params) {
    commit('updatePayoutAccountStatus', null);
    const res = await axios.get(`api/payout-accounts/verify-account-link/`, { params });
    commit('updatePayoutAccountStatus', res.data.data.status);
    return res.data.data;
  },

  async createPayoutAccount(_obj, data) {
    return axios.post(`api/payout-accounts/`, data);
  },
  async deletePayoutAcc({ commit }, data) {
    const res = axios.delete(`api/payout-accounts/unlink-account/`, data);
    commit('updatePayoutAccountStatus', 'unlinked');
    return res.data;
  },
  async intentPay(_obj, params) {
    const res = await axios.get(`api/payout-accounts/payment-intent/`, { params });
    return res.data.data;
  },
  async getPayments(_obj, params) {
    const res = await axios.get(`api/payout-accounts/payouts/`, { params });
    return res.data;
  },
  async getSummary(_obj, params) {
    const res = await axios.get(`api/payout-accounts/payout-account-summary/`, { params });
    return res.data;
  },
  async getExpressDashboardLink(_obj, params) {
    const res = await axios.get(`api/payout-accounts/express-link/`, { params });
    return res.data;
  },
  async updateManuallyPayment(_obj, { id, data }) {
    const res = await axios.patch(`api/payout-accounts/payouts/${id}/update-payment-manually/`, data);
    return res.data.data;
  },
  async deleteManuallyPayment(_obj, id) {
    return axios.delete(`api/payout-accounts/payouts/${id}/delete-payment-manually/`);
  },
  async getSchoolPayAccount(_obj, params) {
    const res = await axios.get(`api/payout-accounts/school-account/`, { params });
    return res.data;
  },

  async getPlansToRefund(_obj, params) {
    const res = await axios.get(`api/payout-accounts/payouts/plans-to-refund/`, { params });
    return res.data;
  },
  async exportPaymentPdf(_obj, params) {
    const resp = await axios.get('api/payout-accounts/payouts/export-student-payments-csv/', { params });
    return resp.data;
  },

  async createProductPaymnetIntent(_obj, data) {
    const res = await axios.post(`api/students/create-product-payment-intent/`, data);
    return res.data;
  },
};

export const payoutAcc = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
