import { USER_ROLES, SCHOOL_PAYMENT_PLANS } from '../common/constants';
import { homeGuard } from '../guards/homeGuard';
import { paymentPlanGaurd } from '../guards/paymentPlanGaurd';
import { validateResetPasswordToken } from '../guards/resetPasswordTokenValidationGuard';

const Layout = () => import(/* webpackChunkName: "layout" */ '../views/Layout.vue');
// const Home = () => import(/* webpackChunkName: "home" */ '../pages/_layout/index.vue');

export const routes = [
  // {
  //   path: '/',
  //   name: '/',
  //   component: Home,
  //   meta: { auth: true }
  // },

  // Default Routes
  {
    path: '/',
    // id: 'layout',
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        // component: Home,
        component: Layout,
        meta: { auth: true },
        beforeEnter: (to, from, next) => {
          homeGuard(to, from, next);
        },
      },
      {
        path: 'manage-preference',
        name: 'manage-preference',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/SubscriptionComponent.vue'),
        meta: { auth: false, onlyUnauth: true, onlyMainDomain: false },
      },

      // Auth Routes
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/SignIn.vue'),
      },
      {
        path: 'logout',
        name: 'logout',
        component: () => import(/* webpackChunkName: "auth" */ '../pages/_layout/logout.vue'),
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/ForgotPass.vue'),
      },

      {
        path: 'reset-password',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/ResetPassword.vue'),
        beforeEnter: validateResetPasswordToken,
      },

      {
        path: 'instructor-onboarding',
        name: 'instructor-signup',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/InstructorSignup.vue'),
        meta: { auth: false, onlyUnauth: true, onlyMainDomain: true },
      },
      {
        path: 'instructor-onboarding/:submission',
        name: 'verify-instructor-signup',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/InstructorSignupSuccessComponent.vue'),
        meta: { auth: false, onlyUnauth: true, onlyMainDomain: true },
      },
      // Account/Profile Routes
      {
        path: 'my-profile',
        name: 'my-profile',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/ManageProfile.vue'),
        meta: { auth: true },
      },
      {
        path: 'change-password',
        name: 'change-password',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/ChangePass.vue'),
        meta: { auth: true },
      },
    ],
  },
  {
    path: '/email-config',
    meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
    component: Layout,
    children: [
      {
        path: '',
        name: 'email-management-list',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SUPER_ADMIN] },
        component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Emails/ListEmails.vue'),
      },
      {
        path: 'system-emails',
        name: 'sa-manage-system-emails',
        meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
        component: () =>
          import(/* webpackChunkName: "emp" */ '../views/SuperAdminPortal/Emails/SystemGeneratedEmails.vue'),
      },
      {
        path: 'email-log',
        name: 'manage-email-logs',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SUPER_ADMIN] },
        component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Emails/EmailLogs.vue'),
      },

      {
        path: ':id',
        name: 'edit-email',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SUPER_ADMIN] },
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Emails/UpdateEmail.vue'),
      },

      {
        path: 'system-generated-email/:id',
        name: 'view-system-email',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SUPER_ADMIN] },
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Emails/SystemEmails.vue'),
      },
    ],
  },

  {
    path: '/website-configuration',
    meta: {
      auth: true,
      rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
      plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
    },
    component: Layout,
    beforeEnter: (to, from, next) => {
      paymentPlanGaurd(to, from, next);
    },
    children: [
      {
        path: 'basic',
        name: 'basic-config',
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
          plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
        },
        component: () =>
          import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AdminBasicConfig.vue'),
      },
      {
        path: 'about',
        name: 'about-page',
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
          plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
        },
        component: () =>
          import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AdminAboutPage.vue'),
      },
      {
        path: 'tuition-and-financing',
        name: 'tuition-page',
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
          plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
        },
        component: () =>
          import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AdminTuitionPage.vue'),
      },
      {
        path: 'gallery',
        name: 'gallery-page',
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
          plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
        },
        component: () =>
          import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AdminGalleryConfig.vue'),
      },
      {
        path: 'home',
        name: 'homepage-config',
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
          plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
        },
        component: () =>
          import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/HomePageConfig.vue'),
      },
      {
        path: 'locations',
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
          plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
        },
        component: Layout,
        children: [
          {
            path: '',
            name: 'campus-page',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AdminCampusPage.vue'),
          },
          {
            path: 'add',
            name: 'add-location',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AdminAddCampusPage.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-campus',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AdminAddCampusPage.vue'),
          },
        ],
      },
      {
        path: 'faqs',
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
          plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
        },
        component: Layout,
        children: [
          {
            path: '',
            name: 'faqs',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AdminFaqs.vue'),
          },
          {
            path: 'add',
            name: 'add-faq',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AdminAddFaq.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-faq',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AdminAddFaq.vue'),
          },
        ],
      },
      {
        path: 'resources',
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
          plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
        },
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-resources',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/ManageResources.vue'),
          },
          {
            path: 'add',
            name: 'add-resource',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AddResource.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-resource',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AddResource.vue'),
          },
        ],
      },
      {
        path: 'custom-pages',
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
          plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
        },
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-custom-pages',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/ManageCustomPages.vue'),
          },
          {
            path: 'add',
            name: 'add-custom-page',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AddCustomPage.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-custom-page',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/WebsiteConfig/AddCustomPage.vue'),
          },
        ],
      },

      //Blog Management
      {
        path: 'blog',
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
          plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
        },
        component: Layout,
        children: [
          {
            path: '',
            name: 'sa-manage-blogs',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Blogs/ListBlog.vue'),
          },
          {
            path: 'add',
            name: 'sa-add-blog',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Blogs/AddBlog.vue'),
          },
          {
            path: 'edit/:id',
            name: 'sa-edit-blog',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.ADVANCED, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Blogs/AddBlog.vue'),
          },
        ],
      },
    ],
  },

  // Super Admin Management Routes
  {
    path: '/admin',
    meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
    component: Layout,
    children: [
      // Dashboard/Home
      {
        path: 'dashboard',
        name: 'admin-dashboard',
        component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Dashboard/Dashboard.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
      },

      //Schools
      {
        path: 'schools',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-schools',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Schools/SchoolList.vue'),
          },
          {
            path: 'add',
            name: 'add-school',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Schools/PostSchool.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-school',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Schools/PostSchool.vue'),
          },
        ],
      },
      {
        path: 'manage-requested-refunds',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-req-refunds',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Schools/RequestedRefunds.vue'),
          },
          {
            path: ':id',
            name: 'sa-refund-details',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Schools/RequestedRefundDetails.vue'),
          },
        ],
      },

      // Interested Schools
      {
        path: 'interested-schools',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-int-schools',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/InterestedSchools/ListInterestedScl.vue'),
          },
          {
            path: 'add',
            name: 'add-int-school',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/InterestedSchools/PostInterestedScl.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-int-school',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/InterestedSchools/PostInterestedScl.vue'),
          },
          {
            path: 'update-status',
            name: 'update-status',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(
                /* webpackChunkName: "adm" */ '../views/SuperAdminPortal/InterestedSchools/UpdateStatusSclForm.vue'
              ),
          },
        ],
      },

      //Programs
      {
        path: 'programs',
        component: Layout,
        children: [
          {
            path: '',
            name: 'sa-manage-programs',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/Programs/ProgramsList.vue'),
          },
          {
            path: 'add',
            name: 'sa-add-program',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/Programs/PostProgram.vue'),
          },
          {
            path: 'edit/:id',
            name: 'sa-edit-program',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/Programs/PostProgram.vue'),
          },
        ],
      },

      //Best School States
      {
        path: 'best-school-states',
        component: Layout,
        children: [
          {
            path: '',
            name: 'sa-manage-states',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/BestSchoolLocation/ListStates.vue'),
          },
          {
            path: 'add',
            name: 'sa-add-state',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/BestSchoolLocation/AddState.vue'),
          },
          {
            path: 'edit/:id',
            name: 'sa-edit-state',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/BestSchoolLocation/AddState.vue'),
          },
        ],
      },
      //Best School Cities
      {
        path: 'best-school-cities',
        component: Layout,
        children: [
          {
            path: '',
            name: 'sa-manage-cities',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/BestSchoolLocation/ListCity.vue'),
          },
          {
            path: 'add',
            name: 'sa-add-city',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/BestSchoolLocation/AddCity.vue'),
          },
          {
            path: 'edit/:id',
            name: 'sa-edit-city',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/BestSchoolLocation/AddCity.vue'),
          },
        ],
      },
      // My AI
      {
        path: 'templates',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-templates',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/MyAI/ListTemplates.vue'),
          },
          {
            path: 'add',
            name: 'add-template',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/MyAI/AddTemplate.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-template',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/MyAI/AddTemplate.vue'),
          },
        ],
      },
      {
        path: 'payments',
        name: 'manage-payouts',
        component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Payouts/ListPayments.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
      },
    ],
  },

  //Users Management
  {
    path: '/users',
    component: Layout,
    meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SUPER_ADMIN] },
    children: [
      {
        path: '',
        name: 'manage-users',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SUPER_ADMIN] },
        component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Users/ListUsers.vue'),
      },
      {
        path: 'add',
        name: 'add-user',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SUPER_ADMIN] },
        component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Users/PostUser.vue'),
      },
      {
        path: 'edit/:id',
        name: 'edit-user',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SUPER_ADMIN] },
        component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Users/PostUser.vue'),
      },
    ],
  },

  // School Portal Routes
  {
    path: '/school',
    meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
    component: Layout,
    name: 'school-home',
    redirect: 'school/dashboard',
    children: [
      // Dashboard
      {
        path: 'dashboard',
        name: 'school-dashboard',
        component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Dashboard/Dashboard.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },

      //School Profile
      {
        path: 'school-profile',
        name: 'manage-school-profile',
        component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Profile/ManageSchoolProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER] },
      },
      //School Enrollment Report
      {
        path: 'enrollment-report',
        name: 'school-enrollment-report',
        component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/EnrollReport/EnrollAnalytics.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      //School Setup Guide
      {
        path: 'setup-guide',
        name: 'school-setup-guide',
        component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/SetupGuide/SchoolSetupGuide.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },

      // School profile public view (use if needed)
      // {
      //   path: 'school-profile/public-view',
      //   name: 'school-profile',
      //   component: () => import(/* webpackChunkName: "emp" */ '../views/EmployerPortal/Schools/SchoolProfile.vue'),
      //   meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] }
      // }
      {
        path: 'program-inquiries',
        name: 'manage-info-list',
        component: () =>
          import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/ProgramInquiries/InquiriesList.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'program-inquiries/:id',
        name: 'info-detail',
        component: () =>
          import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/ProgramInquiries/InquiryDetailPage.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'program-waitlist',
        name: 'manage-wait-list',
        component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Waitlist/ManageWaitlist.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },

      //Students
      {
        path: 'students',
        name: 'schl-students-list',
        component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Students/StudentList.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'pre-enrolled-students',
        name: 'schl-pre-enroll-students-list',
        component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Students/PreEnrolledStdList.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'pre-enroll/:id',
        name: 'schl-pre-enroll-student-profile',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Students/PreEnrolledStdProf.vue'),
      },
      {
        path: 'students/:id',
        name: 'schl-student-profile',
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Students/StudentProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'external-purchaser/:id',
        name: 'external-purchaser',
        component: () =>
          import(/* webpackChunkName: "sch" */ '../views/SchoolPublicLanding/Products/StudentProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },

      //Programs
      {
        path: 'programs',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-programs',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/Programs/ProgramsList.vue'),
          },
          {
            path: 'add',
            name: 'add-program',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/Programs/PostProgram.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-program',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/Programs/PostProgram.vue'),
          },

          // Program FAQs
          {
            path: ':programId/faqs',
            component: Layout,
            children: [
              {
                path: '',
                name: 'prg-faq-management-list',
                meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
                component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/ProgramFAQs/FAQList.vue'),
              },
              {
                path: 'add',
                name: 'add-prg-faq',
                meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
                component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/ProgramFAQs/PostFAQ.vue'),
              },
              {
                path: 'edit/:id',
                name: 'edit-prg-faq',
                meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
                component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/ProgramFAQs/PostFAQ.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'call-logs',
        component: Layout,
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER],
        },
        children: [
          {
            path: '',
            name: 'calls-logs',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER],
            },
            component: () => import(/* webpackChunkName: "adm" */ '../views/CallLogs/CallLogs.vue'),
          },
          {
            path: 'call-logs/:id',
            name: 'call-profile',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER],
            },
            component: () => import(/* webpackChunkName: "adm" */ '../views/CallLogs/CallLogProfile.vue'),
          },
        ],
      },
      {
        path: 'payments',
        component: Layout,
        children: [
          {
            path: 'manage-payments',
            name: 'manage-payment',
            component: () =>
              import(/* webpackChunkName: "account" */ '../views/SchoolPortal/PayoutInfoMngmt/PayoutInfoPage.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER] },
          },
          {
            path: 'payment-history',
            name: 'manage-payment-history',
            component: () =>
              import(/* webpackChunkName: "account" */ '../views/SchoolPortal/PayoutInfoMngmt/PaymentHistory.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER] },
          },
          {
            path: 'refund-requests',
            component: Layout,
            children: [
              {
                path: '',
                name: 'req-refunds',
                meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
                component: () =>
                  import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Schools/RequestedRefunds.vue'),
              },
              {
                path: ':id',
                name: 'refund-details',
                meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
                component: () =>
                  import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Schools/RequestedRefundDetails.vue'),
              },
            ],
          },
        ],
      },
      //Classes
      {
        path: 'classes',
        component: Layout,
        children: [
          {
            path: '',
            name: 'classes-management-list',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Classes/ListClasses.vue'),
          },
          {
            path: 'add',
            name: 'add-class',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Classes/PostClass.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-class',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Classes/PostClass.vue'),
          },
          {
            path: 'registrations/:id',
            name: 'manage-registration-profile',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(
                /* webpackChunkName: "adm" */ '../views/SchoolPortal/Classes/RegisteredStudents/RegistrationProfile.vue'
              ),
          },
        ],
      },
      {
        path: 'initiated-registrations',
        name: 'initiated-registrations',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        component: () =>
          import(
            /* webpackChunkName: "adm" */ '../views/SchoolPortal/Classes/InitiateRegistration/InitiateRegistration.vue'
          ),
      },
      //Location
      {
        path: 'location',
        component: Layout,
        children: [
          {
            path: '',
            name: 'class-location-list',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Classes/Location/ListLocation.vue'),
          },
          {
            path: 'add',
            name: 'add-class-location',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Classes/Location/PostLocation.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-class-location',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Classes/Location/PostLocation.vue'),
          },
        ],
      },
      {
        path: 'instructor',
        component: Layout,
        children: [
          {
            path: '',
            name: 'instructor-management-list',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Instructors/ListInstructor.vue'),
          },
          {
            path: 'add',
            name: 'add-instructor',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Instructors/PostInstructor.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-instructor',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Instructors/PostInstructor.vue'),
          },
        ],
      },

      {
        path: 'national-partners',
        component: Layout,
        children: [
          {
            path: 'listing',
            name: 'national-partners-list',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/NationalPartners/NationalPartners.vue'),
          },
          {
            path: 'affiliations',
            name: 'manage-affiliations-list',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/NationalPartners/AffiliationsList.vue'),
          },
          {
            path: 'programs',
            name: 'national-partners-programs',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/NationalPartners/ProgramsList.vue'),
          },
          {
            path: 'programs/:slug',
            name: 'program-details',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/NationalPartners/ProgramDetails.vue'),
          },
          {
            path: ':id',
            name: 'national-partner-profile',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/NationalPartners/NationalPartnerProfile.vue'),
          },
        ],
      },

      {
        path: 'registration-form',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        component: Layout,
        children: [
          {
            path: ':program/add-section',
            name: 'add-section',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/RegFormSettings/AddSectionPage.vue'),
          },
          {
            path: ':program/edit-section/:id',
            name: 'edit-section',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/RegFormSettings/AddSectionPage.vue'),
          },
          {
            path: 'configure-student-info',
            name: 'configure-reg-static-fields',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/RegFormSettings/StaticFieldsPage.vue'),
          },
          {
            path: ':id',
            name: 'manage-registration-form',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/RegFormSettings/ManageRegistrationForm.vue'),
          },
        ],
      },
      {
        path: 'pre-enrollment-form',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        component: Layout,
        children: [
          {
            path: ':program/add-pre-enroll-section',
            name: 'add-pre-enroll-section',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/RegFormSettings/AddSectionPage.vue'),
          },
          {
            path: ':program/edit-pre-enroll-section/:id',
            name: 'edit-pre-enroll-section',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/RegFormSettings/AddSectionPage.vue'),
          },
          {
            path: 'configure-pre-enrollment-student-info',
            name: 'configure-pre-enrollment-static-fields',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/RegFormSettings/StaticFieldsPage.vue'),
          },
          {
            path: ':id',
            name: 'manage-pre-enrollment-form',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/RegFormSettings/ManageRegistrationForm.vue'),
          },
        ],
      },
      {
        path: 'custom-questions',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-questions',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Questions/QuestionsList.vue'),
          },
          {
            path: 'add',
            name: 'add-question',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Questions/PostQuestion.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-question',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Questions/PostQuestion.vue'),
          },
        ],
      },

      // {
      //   path: 'email-config',
      //   component: Layout,
      //   children: [
      //     {
      //       path: '',
      //       name: 'manage-emails',
      //       meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      //       component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Emails/EmailList.vue')
      //     },
      //     {
      //       path: 'email-log',
      //       name: 'manage-email-logs',
      //       meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      //       component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Emails/EmailLogs.vue')
      //     },

      //     {
      //       path: ':id',
      //       name: 'edit-email',
      //       meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      //       component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Emails/UpdateEmail.vue')
      //     },

      //   ]
      // },
      {
        path: 'products',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-product-list',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Products/ProductList.vue'),
          },
          {
            path: 'add',
            name: 'add-product',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Products/PostProduct.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-product',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Products/PostProduct.vue'),
          },
        ],
      },
      {
        path: 'purchases',
        name: 'manage-purchase-list',
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Products/PurchaseList.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'external-purchases',
        name: 'manage-external-purchases',
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Products/ExternalPurchases.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'link-product',
        name: 'manage-link-product-list',
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Products/LinkProductList.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'inquiry-widget',
        name: 'manage-inquiry-widget',
        component: () =>
          import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/InquiryWidget/InquiryWidgetDetails.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'custom-navbar-menu',
        name: 'custom-navbar-menu',
        component: () =>
          import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/CustomNavbarMenu/CustomNavbarMenu.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },

      {
        path: 'marketing',
        component: Layout,
        beforeEnter: (to, from, next) => {
          paymentPlanGaurd(to, from, next);
        },
        children: [
          {
            path: '',
            name: 'marketing',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.BASIC_AND_CHAT, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Marketing/CreatePost.vue'),
          },
        ],
      },
      {
        path: 'chat',
        component: Layout,
        beforeEnter: (to, from, next) => {
          paymentPlanGaurd(to, from, next);
        },
        children: [
          {
            path: '',
            name: 'chat-bot',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN],
              plansRequired: [SCHOOL_PAYMENT_PLANS.BASIC_AND_CHAT, SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT],
            },
            component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Chat/ChatBot.vue'),
          },
        ],
      },
      {
        path: 'feature-updates',
        name: 'feature-updates-page',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        component: () =>
          import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/FeatureUpdates/FeatureUpdates.vue'),
      },
      {
        path: 'feature-updates/:id',
        name: 'feature-update-details',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        component: () =>
          import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/FeatureUpdates/FeatureUpdateDetails.vue'),
      },
    ],
  },

  {
    path: '/school-landing',
    meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
    component: Layout,
    children: [
      // Home
      {
        path: '',
        name: 'sch-public-home',
        component: () => import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Home/SchlPublicHome.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
      //Program List
      {
        path: '/programs',
        name: 'public-sch-programs',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Programs/ProgramsListPage.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
      {
        path: '/programs/:slug',
        name: 'program-detail',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Programs/ProgramDetailPage.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
      {
        path: '/registration',
        name: 'class-registration',
        component: () =>
          import(
            /* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Programs/Registration/RegistrationForm.vue'
          ),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
      {
        path: '/pre-enroll-form',
        name: 'class-pre-enrollment',
        component: () =>
          import(
            /* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Programs/Registration/PreEnroll/PreEnrollForm.vue'
          ),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
      {
        path: '/sponsorships/:id',
        name: 'public-sponsorships',
        component: () =>
          import(
            /* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Programs/DetailsTab/SponsorshipDetails.vue'
          ),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
    ],
  },

  {
    path: '/store',
    component: Layout,
    meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
    children: [
      {
        path: '',
        name: 'sch-public-store',
        component: () => import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Products/ProductsList.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
      {
        path: 'checkout',
        name: 'checkout-store',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Products/ProductsPayment.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
      {
        path: ':slug',
        name: 'sch-public-product',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Products/ProductDetail.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
    ],
  },
];
