<template>
  <b-modal ref="cartModal" hide-footer title="Cart" centered scrollable @hide="hideModal" size="lg">
    <div class="d-flex align-items-center">
      <div class="flex" v-if="getCartArr.length > 0">
        <b-table
          :fields="tableColumns"
          :items="getCartArr"
          head-variant="light"
          class="table-nowrap"
          hover
          responsive
          no-local-sorting
        >
          <template #cell(product_title)="data">
            <div @click="goToProduct(data.item.slug)" class="d-flex align-items-center clickable-item">
              <fmv-avatar :title="true" title-class="bg-transparent" no-link slot="aside">
                <b-img
                  :src="data.item.product_url"
                  class="img-fluid"
                  width="40"
                  alt="Logo"
                  v-if="data.item.product_url"
                />
                <i class="fas fa-bag-shopping" v-else></i>
              </fmv-avatar>
              {{
                truncate(data.item.product_title, {
                  length: 40,
                })
              }}
            </div>
          </template>
          <template #cell(price)="data">{{ $n(data.item.price, 'currency', 'en-US') }}</template>
          <template #cell(quantity)="data">
            <a
              href="#"
              class="text-danger"
              @click.prevent="() => (data.item.quantity > 1 ? decrementQuantity(data.index) : null)"
            >
              <i class="fas fa-minus-square"></i>
            </a>

            {{ $n(data.item.quantity) }}
            <a href="#" class="text-primary mr-2" @click.prevent="incrementQuantity(data.index)">
              <i class="fas fa-plus-square"></i>
            </a>
          </template>

          <template #cell(amount)="data">{{ $n(data.item.price * data.item.quantity, 'currency', 'en-US') }}</template>

          <template #head(actions)="">
            <span></span>
          </template>
          <template #cell(actions)="data">
            <a href="#" @click.prevent="deleteProduct(data.item.id)" v-b-popover.hover.right :title="'Delete Product'">
              <i class="material-icons text-danger">delete</i>
            </a>
          </template>
          <template v-slot:custom-foot>
            <tr>
              <th>Total</th>
              <th />
              <th />
              <th />
              <th />
              <th>{{ $n(totalAmount, 'currency', 'en-US') }}</th>
            </tr>
          </template>
        </b-table>
      </div>
      <i v-else> No items added in cart</i>
    </div>
    <b-btn
      v-if="getCartArr.length"
      variant="primary"
      style="width: 150px"
      @click.prevent="continueToDetails"
      class="btn-normal float-right my-3"
      :disabled="!getCartArr.length"
    >
      <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
      <span v-else>Checkout</span>
    </b-btn>
  </b-modal>
</template>

<script>
import { truncate } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';

export default {
  components: { FmvAvatar },
  props: {
    showModal: { type: Boolean, default: false },
  },
  data() {
    return {
      DefaultAvatar,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('productsStore', ['getCartArr']),

    tableColumns() {
      return [
        { key: 'product_title', label: 'Product' },
        { key: 'price', label: 'Price' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'amount', label: 'Amount' },
        { key: 'size', label: 'Size' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },

    cartData() {
      return this.getCartArr;
    },

    totalAmount() {
      return this.getCartArr.reduce((total, item) => item.price * item.quantity + total, 0);
    },
  },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.cartModal.show();
      }
    },
  },

  methods: {
    ...mapActions('productsStore', ['addToCart', 'incrementQuantity', 'decrementQuantity', 'removeFromCart']),
    truncate,

    deleteProduct(id) {
      this.makeToast({ variant: 'success', msg: 'Product removed from cart.' });
      this.removeFromCart(id);
    },

    hideModal() {
      this.$emit('close');
    },

    goToProduct(slug) {
      window.location.href = `/store/${slug}`;
      this.hideModalManual();
    },

    hideModalManual() {
      this.$refs.cartModal.hide();
      this.hideModal();
    },

    continueToDetails() {
      this.$router.push('/store/checkout');
      this.hideModalManual();
    },
  },
};
</script>

<style></style>
